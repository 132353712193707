import { Commit } from 'vuex'
import createUserServices from '@/services/user'
import createSettingServices from '@/services/setting'
import roleSettingServices from '@/services/role'
import { Message } from 'element-ui'
import Cookie from 'cookies-js'
import route from '@/router/routes'
import router from '@/router'
let flag = false
const userServices = createUserServices()
const settingServices = createSettingServices()
const roleServices = roleSettingServices()

const copyList = (arr: any) => {
  const result = []
  for (const item of arr) {
    result.push(deepclone(item))
  }
  return result
}
const deepclone: any = (obj: any) => {
  if (typeof obj === 'object') {
    // console.log(1, '是否死循环')
    if (Array.isArray(obj)) {
      return copyList(obj)
    } else {
      const result: any = {}
      for (const key in obj) {
        result[key] = deepclone(obj[key])
      }
      return result
    }
  } else {
    return obj
  }
}
const getRedirect = (id: any, path: any, list: any) => {
  const arr = list.filter((item: any) => {
    return item.parent === id
  })
  if (arr.length) {
    return path + '/' + arr[0].path
  } else {
    return ''
  }
}

const state: any = {
  userInfo: null,
  routers: route.baseRoutes,
  isAuthenticated: false,
  buttonMap: {},
	rightsRouters: []
}

let routers: any[] = []
let buttonMap: any = []
const setRouter = (
  routers: Array<any>,
  parentId: number,
  target: Array<any>
) => {
  for (let i = 0; i < routers.length; i++) {
    routers[i].parent = routers[i].parent ? routers[i].parent : 0
    if (routers[i].parent === parentId) {
      const route = routers.splice(i, 1)[0]
      const routeItem: any = {
        id: route.id,
        path: route.path,
        name: route.name,
        meta: {
          keepAlive: (route.meta as any).keepAlive || false,
          name: (route.meta as any).name,
        },
        redirect: route.redirect || '',
        component: () => import('@/' + route.component),
      }
      target.push(routeItem)
      i--
    }
  }
  target.forEach((item: any) => {
    item.children = []
    setRouter(routers, item.id, item.children)
  })
}

const mutations: any = {
  SET_AUTHENTICATED(state: any, Authenticated: boolean) {
    state.isAuthenticated = Authenticated
  },
  SET_BUTTONMAP(state: any, buttonMap: any) {
    state.buttonMap = buttonMap
  },
  SET_ROUTER(state: any, routers: []) {
    // 深克隆初始化数组
    state.routers = deepclone(route.baseRoutes)
    state.routers[0].children.splice(1, 1)
    // console.log('route', route.baseRoutes, state.routers)
    state.routers[0].children.push(
      ...routers
      //   {
      //   path: '*',
      //   redirect: '/',
      // }
    )
    router.addRoutes(state.routers)
  },
  UPDATE_USER_INFO(state: any, userInfo: any) {
    state.userInfo = userInfo
  },
	SET_RIGHTS_ROUTERS(state: any, routers: any) {
    let base = route.base.map((item: any) => item.name)
		state.rightsRouters = [...routers, ...base]
    // console.log('state.rightsRouters', state.rightsRouters, route.base)
	}
}

const actions: any = {
  async setAuthenticated(context: { commit: Commit }) {
    const res = await settingServices.isAuthenticated()
    if (res.status === 201) {
      context.commit('SET_AUTHENTICATED', res.data)
    } else {
      Message.error(res.msg)
    }
  },
  async getUserInfo(context: { commit: Commit }) {
    if (flag) {
      return
    }
    flag = true
    const { status, infoMsg, data } = await userServices.getUserInfo()
    if (status !== 201) {
      Message({ message: infoMsg, type: 'error' })
      const { status, msg } = await userServices.logout()
      if (status !== 201) {
        Message({ message: msg, type: 'error' })
        // return
      }
      Cookie.expire('sessionid')
      Cookie.expire('DTCsrfToken')
      // window.localStorage.clear()
      window.location.reload()
      return
    }
    const res = await roleServices.roleRoute()
		if (res.status != 201) return
    const sererRouters = res.data.routes
    const replaceRouters:any = [];
    sererRouters.map((item:any) => {
      if (item == 'integrationManagement') {
        item = 'stting'
      }
      if (item == 'strategyBox') {
        item = 'policyManage'
      }
      if (item == 'strategyManage') {
        item = 'passivePolicy'
      }
      if (item == 'templateManage') {
        item = 'settingTemplate'
      }
      if (item == 'sensitiveManage') {
        item = 'settingSensitive'
      }
      if (item == 'strategy') {
        item = 'policy'
      }

      if (item == 'license') {
        item = 'subscribe'
      }
      if (item == 'agentManage') {
        item = 'agent'
      }

      if (item == 'scanList' || item == 'scaList') {
        item = 'packageList'
      }

      if (item == 'agentManage') {
        item = 'agent'
      }
      if (item == 'team') {
        item = 'groups'
      }

      if (item == 'projectManage') {
        item = 'pList'
      }

      if (item == 'authority') {
        item = 'permission'
      }

      if (item == 'changeLogo') {
        item = 'settingLogo'
      }
      if (item == 'logManage') {
        item = 'audit'
      }
      if (item == 'vulnSharing') {
        item = 'Share'
      }
      if (item == 'reportCenter') {
        item = 'report'
      }
      if (item == 'search') {
        item = 'data'
      }
      if (item == 'roleSetting') {
        item = 'role'
      }
      if (item == 'account') {
        item = 'id'
      }
      replaceRouters.push(item)
    })
    console.log(" ======= this is server routers ========= ")
    console.log(replaceRouters);
    let rightsRouters: any = route.rightsRouters.filter((item: any) => replaceRouters.includes(item.id))
    setRouter([...route.routes, ...rightsRouters], 0, routers)
    buttonMap = res.data.buttons.map((item: any) => item.id)
		context.commit('SET_RIGHTS_ROUTERS', replaceRouters)
    context.commit('SET_ROUTER', routers)
    context.commit('SET_BUTTONMAP', buttonMap)
    context.commit('UPDATE_USER_INFO', data)
  },
  async logOut(context: { commit: Commit }) {
    const { status, msg } = await userServices.logout()
    if (status !== 201) {
      Message({ message: msg, type: 'error' })
      return
    }
    context.commit('clearInfo')
    // window.localStorage.clear()
    window.location.reload()
  },
  clearInfo(context: { commit: Commit }) {
    flag = false
    Cookie.expire('sessionid')
    Cookie.expire('DTCsrfToken')
    context.commit('UPDATE_USER_INFO', null)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
