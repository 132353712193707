import request from '@/utils/request'
import qs from 'qs'
import { iResponse } from '@/services/types'

export default () =>
  new (class {
    getRoles(params: any): Promise<iResponse> {
      return request.get('/role/list', { params })
    }

    addRoles(data: any): Promise<iResponse> {
      return request.post('/role/create', data)
    }

    // deleteRoles(role_id: any): Promise<iResponse> {
    //   return request.delete(`/role/route`)
    // }

    roleRouteList(): Promise<iResponse> {
      return request.get(`/role/route/list`)
    }

    roleRoute(): Promise<iResponse> {
      return request.get(`/route`, {
        baseURL: '/api/v2',
      })
    }

    role(id: string): Promise<iResponse> {
      return request.get(`/role/${id}`)
    }

    roleUpdate(id: string, data: any): Promise<iResponse> {
      return request.put(`/role/${id}`, data)
    }

    roleDelete(id: string): Promise<iResponse> {
      return request.delete(`/role/${id}`)
    }
  })()
