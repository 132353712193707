import { RouteConfig } from 'vue-router'
import { i18n } from '@/config/lang'

const base = [
	{
		path: 'login',
		name: 'login',
		meta: {
			i18n: 'menu.login',
			name: i18n.t('menu.login'),
			disabled: true,
		},
		component: () => import('@/views/Login.vue'),
	},
	{
		path: 'home',
		name: 'home',
		meta: {
			name: "首页"
		},
		component: () => import('@/views/dashboard/dashboard.vue'),
	},
	{
		name: "subscribe",
		path: "/subscribe",
		component: () => import('@/views/setting/license.vue'),
		meta: {
			name: "授权更新"
		},
	},
	{
		path: 'settingCode',
		name: 'settingCode',
		meta: {
			disabled: true,
			i18n: 'menu.changePassword',
			name: i18n.t('menu.changePassword'),
		},
		component: () => import('@/views/setting/ChangePassword.vue'),
	},
	// {
	// 	path: 'knowledge/:name',
	// 	name: 'knowledge',
	// 	meta: {
	// 		name: 'microview知识库',
	// 	},
	// 	component: () => import('@/views/iframe/index.vue'),
	// },
	// {
	// 	path: "environment",
	// 	name: "environment",
	// 	meta: {
	// 		name: "环境部署"
	// 	},
	// 	component: () => import('@/views/iframe/transfer.vue'),
	// },
	// {
	// 	path: "investigate",
	// 	name: "investigate",
	// 	meta: {
	// 		name: "巡检排查"
	// 	},
	// 	component: () => import('@/views/iframe/transfer.vue'),
	// }
]
const baseRoutes: Array<RouteConfig> = [
	{
		path: '/',
		name: '/',
		redirect: '/login',
		meta: {
			i18n: 'menu.home',
			name: i18n.t('menu.home'),
		},
		component: () => import('@/views/layout/Index.vue'),
		children: [...base],
	},
	{
		path: '/implant',
		name: 'implant',
		redirect: '/login',
		component: () => import('@/views/implant/Index.vue'),
		meta: {
			isMenu: true,
			mame: '嵌入',
			disabled: true,
		},
		children: [
			{
				path: 'projectDetail',
				name: 'implantProjectDetail',
				meta: {
					disabled: true,
					i18n: 'menu.projectDetail',
					name: i18n.t('menu.projectDetail'),
				},
				component: () => import('@/views/implant/ProjectDetail.vue'),
			},
			{
				path: 'projectEdit/:pid?',
				name: 'implantProjectEdit/:pid?',
				meta: {
					disabled: true,
					i18n: 'menu.projectEdit',
					name: i18n.t('menu.projectEdit'),
				},
				component: () => import('@/views/implant/ProjectEdit.vue'),
			},
			{
				path: 'vulnDetail',
				name: 'implantVulnDetail',
				meta: {
					i18n: 'menu.vulnDetail',
					name: i18n.t('menu.vulnDetail'),
				},
				component: () => import('@/views/implant/VulnDetail.vue'),
			},
		],
	},
	{
		path: '/share',
		name: 'share',
		redirect: '/login',
		component: () => import('@/views/sca/Index.vue'),
		meta: {
			isMenu: true,
			mame: '分享',
			disabled: true,
		},
		children: [
			{
				path: 'packageDetail/:page/:id',
				name: 'packageDetail/:page/:id',
				meta: {
					disabled: true,
					name: '组件详情',
				},
				component: () => import('@/views/sca/ScaDialog.vue'),
			},
			{
				path: 'vulnDetail/:page/:id',
				name: 'shareVulnDetail/:page/:id',
				meta: {
					name: '应用漏洞详情',
				},
				component: () => import('@/views/vuln/VulnDetail.vue'),
			},
		],
	},
]

const routes = [
	{
		id: "appEdit/:pid?",
		name: "appEdit/:pid?",
		path: "appEdit/:pid?",
		component: "views/project/ProjectEdit.vue",
		parent: "project",
		meta: {
			name: "编辑项目"
		},
		buttons: []
	},
	{
		id: "appDetail/:pid",
		name: "appDetail/:pid",
		path: "appDetail/:pid",
		component: "views/project/ProjectDetail.vue",
		parent: "project",
		meta: {
			name: "项目详情"
		},
		buttons: ["项目漏洞删除", "项目漏洞验证", "项目集成同步"]
	},
	{
		"id": "appExport/:pid?",
		"name": "appExport/:pid?",
		"path": "appExport/:pid?",
		"component": "views/project/ProjectExport.vue",
		"parent": "project",
		"meta": {
			"name": "生成报告"
		},
		"redirect": "",
		"buttons": []
	},
	{
		id: "leakDetail/:page/:id",
		name: "leakDetail/:page/:id",
		path: "leakDetail/:page/:id",
		component: "views/vuln/VulnDetail.vue",
		parent: "project",
		meta: {
			name: "应用漏洞详情"
		},
		buttons: ["详情漏洞分享", "详情漏洞验证", "详情集成同步"]
	},
	{
		id: "dastDetail/:page/:id",
		name: "dastDetail/:page/:id",
		path: "dastDetail/:page/:id",
		component: "views/sca/ScanDetail.vue",
		parent: "project",
		meta: {
			name: "黑盒漏洞详情"
		},
		buttons: []
	},
	{
		id: "packageDetail/:page/:id",
		name: "packageDetail/:page/:id",
		path: "packageDetail/:page/:id",
		component: "views/sca/ScaDialog.vue",
		parent: "project",
		meta: {
			name: "组件漏洞详情"
		},
		"buttons": ["组件漏洞分享"]
	},
	{
		id: "sysInfo",
		name: "sysInfo",
		path: "sysInfo",
		component: "views/setting/SysInfo.vue",
		parent: "project",
		meta: {
			name: "熔断配置"
		},
	},
	{
		"id": "setting",
		"name": "setting",
		"path": "setting",
		"component": "views/setting/Index.vue",
		// component: "views/setting/integration.vue",
		"parent": 0,
		"meta": {
			"name": "集成管理"
		},
		"buttons": []
	},
	{
		id: "jira",
		name: "jira",
		path: "jira",
		component: "views/setting/jira.vue",
		parent: "setting",
		meta: {
			name: "Jira"
		},
		"buttons": []
	},
	{
		id: "settingZentao",
		name: "settingZentao",
		path: "settingZentao",
		component: "views/setting/chandao.vue",
		parent: "setting",
		meta: {
			name: "禅道"
		},
		"buttons": []
	},
	{
		id: "settingGitlab",
		name: "settingGitlab",
		path: "settingGitlab",
		component: "views/setting/gitlab.vue",
		parent: "setting",
		meta: {
			name: "GitLab"
		},
		"buttons": []
	},
	{
		id: "settingJenkins",
		name: "settingJenkins",
		path: "settingJenkins",
		component: "views/setting/jenkins.vue",
		parent: "setting",
		meta: {
			name: "Jenkins"
		},
		"buttons": []
	},
	{
		"id": "settingFeishu",
		"name": "settingFeishu",
		"path": "settingFeishu",
		"component": "views/setting/feishu.vue",
		"parent": "setting",
		"meta": {
			"name": "飞书"
		},
		"buttons": []
	},
	{
		"id": "settingDingtalk",
		"name": "settingDingtalk",
		"path": "settingDingtalk",
		"component": "views/setting/dingding.vue",
		"parent": "setting",
		"meta": {
			"name": "钉钉",
		},
		"buttons": []
	},
	{
		"id": "settingWorkwx",
		"name": "settingWorkwx",
		"path": "settingWorkwx",
		"component": "views/setting/weixin.vue",
		"parent": "setting",
		"meta": {
			"name": "企业微信"
		},
		"redirect": "",
		"buttons": []
	},
	{
		"id": "settinghook",
		"name": "settinghook",
		"path": "settinghook",
		"component": "views/setting/webhook.vue",
		"parent": "setting",
		"meta": {
			"name": "webhook"
		},
		"redirect": "",
		"buttons": []
	},
	{
		"id": "settingIDEA",
		"name": "settingIDEA",
		"path": "settingIDEA",
		"component": "views/setting/intelliJ.vue",
		"parent": "setting",
		"meta": {
			"name": "IntelliJ"
		},
		"redirect": "",
		"buttons": []
	},
	{
		"id": "settingBlueKing",
		"name": "settingBlueKing",
		"path": "settingBlueKing",
		"component": "views/setting/lanjing.vue",
		"parent": "setting",
		"meta": {
			"name": "蓝鲸智云",
		},
		"buttons": []
	},
	{
		"id": "settingYunXiao",
		"name": "settingYunXiao",
		"path": "settingYunXiao",
		"component": "views/setting/yunxiao.vue",
		"parent": "setting",
		"meta": {
			"name": "云效"
		},
		"buttons": []
	},
	{
		"id": "settingK8S",
		"name": "settingK8S",
		"path": "settingK8S",
		"component": "views/setting/k8s.vue",
		"parent": "setting",
		"meta": {
			"name": "kubernetes"
		},
		"redirect": "",
		"buttons": []
	},
	{
		"id": "policy",
		"name": "policy",
		"path": "policy",
		"component": "views/setting/Strategy.vue",
		"parent": "policyManage",
		"meta": {
			"name": "编辑检测策略"
		},
		"redirect": "",
	},
	{
		"id": "template",
		"name": "template",
		"path": "template",
		"component": "views/setting/template.vue",
		"parent": "policyManage",
		"meta": {
			"name": "编辑策略模版"
		},
		"redirect": "",
		"buttons": []
	},
	{
		"id": "sensitive",
		"name": "sensitive",
		"path": "sensitive",
		"component": "views/setting/sensitive.vue",
		"parent": "policyManage",
		"meta": {
			"name": "敏感信息"
		},
		"redirect": "",
		"buttons": []
	},
	{
		id: 'poolDetail',
		path: 'poolDetail/:id',
		name: 'poolDetail',
		meta: {
			name: "污点详情",
		},
		component: "views/taint/PoolDetail.vue",
		"buttons": []
	},
	{
		id: "addRole",
		path: "addRole",
		name: "addRole",
		meta: {
			name: "新增角色"
		},
		parent: "permission",
		component: "views/authority/addRole.vue",
	},
]

let rightsRouters = [
	// {
	// 	"id": "links",
	// 	"name": "links",
	// 	"path": "/links",
	// 	"component": "views/links/index.vue",
	// 	"meta": {
	// 		"name": "关联图谱"
	// 	},
	// 	"redirect": "",
	// 	"buttons": []
	// },
	{
		id: "project",
		name: "project",
		path: "/project",
		component: "views/project/Index.vue",
		parent: 0,
		meta: {
			name: "项目管理"
		},
		redirect: "/project/pList",
		buttons: []
	},
	{
		id: "groups",
		path: "groups",
		name: "groups",
		meta: {
			name: "项目管理"
		},
		parent: "project",
		component: "views/project/ProjectGroup.vue",
	},
	{
		id: "pList",
		name: "pList",
		path: "pList",
		component: "views/project/ProjectManage.vue",
		parent: "project",
		meta: {
			name: "应用列表"
		},
		buttons: ["项目删除"]
	},
	{
		id: "vulnList",
		path: "vulnList",
		name: "vulnList",
		meta: {
			name: '应用漏洞',
		},
		parent: "project",
		component: 'views/vuln/VulnList.vue',
		buttons: ["漏洞删除"]
	},
	// {
	// 	id: "scanList",
	// 	path: "scanList",
	// 	name: "scanList",
	// 	meta: {
	// 		name: "黑盒漏洞",
	// 	},
	// 	parent: "project",
	// 	component: 'views/vuln/ScanList.vue',
	// 	buttons: []
	// },
	{
		id: "packageList",
		name: "packageList",
		path: "packageList",
		component: "views/sca/ScaList.vue",
		parent: "project",
		meta: {
			name: "组件漏洞"
		},
		buttons: []
	},
	{
		id: "agent",
		name: "agent",
		path: "agent",
		component: "views/setting/AgentManage.vue",
		parent: "project",
		meta: {
			name: "Agent 管理"
		},
	},
	{
		id: "deployment",
		name: "deployment",
		path: "/deployment",
		component: "views/project/Index.vue",
		parent: 0,
		meta: {
			name: "部署指南",
			icon: 'iconzhinan',
		},
		redirect: "",
		buttons: []
	},
	{
		id: "deploy",
		name: "deploy",
		path: "deploy",
		component: "views/deploy/Deploy.vue",
		parent: "deployment",
		meta: {
			name: "Agent 安装"
		},
		buttons: []
	},
	{
		id: "stting",
		name: "stting",
		path: "stting",
		component: "views/setting/integration.vue",
		parent: "setting",
		meta: {
			name: "集成管理"
		},
		buttons: []
	},
	{
		id: "policyManage",
		name: "policyManage",
		path: "/policyManage",
		component: "views/setting/Index.vue",
		parent: 0,
		meta: {
			name: "策略管理"
		},
	},
	{
		"id": "passivePolicy",
		"name": "passivePolicy",
		"path": "passivePolicy",
		"component": "views/setting/StrategyManage.vue",
		"parent": "policyManage",
		"meta": {
			"name": "检测策略"
		},
		"redirect": "",
	},
	{
		"id": "settingTemplate",
		"name": "settingTemplate",
		"path": "settingTemplate",
		"component": "views/setting/templateManage.vue",
		"parent": "policyManage",
		"meta": {
			"name": "策略模版",
		},
		"redirect": "",
	},
	// {
	// 	"id": "hookRuleRouterView",
	// 	"name": "hookRuleRouterView",
	// 	"path": "hookRuleRouterView",
	// 	"component": "views/setting/hookRuleIndex.vue",
	// 	"parent": "policyManage",
	// 	"meta": {
	// 		"name": "规则设置"
	// 	},
	// 	"redirect": "",
	// },
	{
		"id": "hookRule",
		"name": "hookRule",
		"path": "hookRule",
		"component": "views/setting/hookRule.vue",
		"parent": "policyManage",
		"meta": {
			"name": "自定义规则"
		},
		"redirect": "",
	},
	{
		"id": "settingSensitive",
		"name": "settingSensitive",
		"path": "settingSensitive",
		"component": "views/setting/sensitiveManage.vue",
		"parent": "policyManage",
		"meta": {
			"name": "敏感信息配置"
		},
		"redirect": "",
	},
	// {
	// 	"id": "projectTemplate",
	// 	"name": "projectTemplate",
	// 	"path": "projectTemplate",
	// 	"component": "views/setting/projectTemplate.vue",
	// 	"parent": "strategyBox",
	// 	"meta": {
	// 		"name": "项目模版"
	// 	},
	// 	"redirect": "",
	// },
	{
		"id": "systemSettings",
		"name": "systemSettings",
		"path": "systemSettings",
		"component": "views/setting/SystemSettings.vue",
		"parent": "policyManage",
		"meta": {
			"name": "系统设置",
		},
		"redirect": "",
	},
	{
		id: 'data',
		path: 'data',
		name: 'data',
		meta: {
			name: "数据查询",
			keepAlive: true,
		},
		component: "views/taint/Search.vue",
		"buttons": []
	},
	{
		id: "report",
		name: "report",
		path: "/report",
		component: "views/project/Index.vue",
		parent: 0,
		meta: {
			name: "报告中心"
		},
	},
	{
		"id": "report",
		"name": "report",
		"path": "report",
		"component": "views/project/reportCenter.vue",
		"parent": "report",
		"meta": {
			"name": "应用报告"
		},
		"redirect": "",
	},
	{
		"id": "Share",
		"name": "Share",
		"path": "Share",
		"component": "views/records/vulnSharing.vue",
		"parent": "report",
		"meta": {
			"name": "分享记录",
		},
		"redirect": "",
	},
	{
		"id": "settingLogo",
		"name": "settingLogo",
		"path": "settingLogo",
		"component": "views/setting/changeLogo.vue",
		"meta": {
			"name": "Logo配置"
		},
		"redirect": "",
		"buttons": []
	},
	{
		"id": "audit",
		"name": "audit",
		"path": "audit",
		"component": "views/setting/LogManage.vue",
		"meta": {
			"name": "操作日志"
		},
		"redirect": "",
		"buttons": ["删除日志", "导出日志", "清空日志"]
	},
	{
		"id": "about",
		"name": "about",
		"path": "about",
		"component": "views/setting/about.vue",
		"meta": {
			"name": "关于MicroView"
		},
		"redirect": "",
		"buttons": []
	},
	{
		id: 'permission',
		path: '/permission',
		name: 'permission',
		meta: {
			name: '权限管理',
		},
		component: "views/authority/index.vue",
	},
	{
		id: "role",
		path: "role",
		name: "role",
		meta: {
			name: "角色管理"
		},
		parent: "permission",
		component: "views/authority/components/roleSetting.vue",
	},

	{
		id: "id",
		path: "id",
		name: "id",
		meta: {
			name: "账号管理"
		},
		parent: "permission",
		component: "views/authority/components/account.vue",
	},
]

export default {
	base,
	baseRoutes,
	routes,
	rightsRouters
}
