const state: any = {
  showSidebar: false,
}

const mutations: any = {
  CHANGE_SIDEBAR_STATUS(state: any, status: any) {
    state.showSidebar = status
  },
}
const actions = {
  changeSidebarStatus(context: any, val: any) {
    context.commit('CHANGE_SIDEBAR_STATUS', val)
  },
}

export default {
  state,
  mutations,
  actions,
}
