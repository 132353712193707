import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import Nprogress from 'nprogress'
import { getToken } from '@/utils/utils'
import { i18n } from '@/config/lang'
import { Message } from 'element-ui'

import { pathToRegexp } from 'path-to-regexp'

import { sidebarMenu } from '@/views/layout/data'

Vue.use(VueRouter)

// const createRouter: any = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes: routes.baseRoutes,
// })
const router: any = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes.baseRoutes,
})

let reloadNum = 0

let authenticated: any = null

const whiteList = ['/taint', '/taint/search', '/taint/poolDetail', '/login']
const implantList = ['/appDetail', '/leakDetail']

const isWhiteList = (path: string) => {
  return whiteList.includes(path)
}
const isImplantList = (path: string) => {
  // console.log(path.replace('/implant', ''), implantList.includes(path.replace('/implant', '')))
  return implantList.includes(path.replace('/implant', ''))
}

let menu = sidebarMenu

router.beforeEach(async (to: any, from: any, next: any) => {
  // console.log('to', to)
  if(to.path.startsWith('/implant/')) {
    sessionStorage.isImplant = true
    if(isImplantList(to.path)) {
      sessionStorage.token = to.query.token
    }
    next()
    return
  }
  if(to.path.startsWith('/share/')) {
    sessionStorage.isShare = true
    // console.log('share')
    next()
    return
  }
  // if (to.path.startsWith('/dongtai_doc/')) {
  //   next()
  //   return
  // }
  // console.log('no implant')
  sessionStorage.isImplant = false
  sessionStorage.isShare = false
  if (authenticated === null) {
    await store.dispatch('user/setAuthenticated')
    authenticated = store.getters.isAuthenticated
  }

  Nprogress.start()
  if (!getToken() && !isWhiteList(to.path)) {
    store.dispatch('user/clearInfo')
    // 这里刷新了下页面
    // store.dispatch('user/logOut')
    // console.log('router.getRoutes()', router.getRoutes())
    // const removeArr = router.getRoutes().filter((item: any) => {
    //   return !isWhiteList(item.path)
    // })
    // vue3 router4中的方法当前不适用
    // removeArr.forEach((route: any) => {
    //   router.removeRoute(route.name)
    // })
    // 路由删除
    // router.matcher = createRouter.matcher
    // console.log('router.getRoutes()', router.getRoutes())
    setTimeout(() => {
      // window.localStorage.clear()
      // window.location.reload()
    }, 100)
    next('/login')

    return
  }

  let showSidebar = getDefaultActive()

  store.dispatch('changeSidebarStatus', showSidebar)

  function getDefaultActive() {
		let arr = menu.map((item: any) => {
			if(item.hidden) {
				return item
			}
			return [...item.children]
		}).flat()
		const name = to.name
		for (let i = 0; i < arr.length; i++) {
			const item = arr[i];
			if(item.name === name) return true
			if(item.children && item.children.length > 0) {
				let flag = item.children.find((ele: any) => ele.name === name)
				if(flag) return true
			}
		}
		return false
	}

  if (!getToken() && isWhiteList(to.path)) {
    next()
    return
  }

  if (getToken() && !store.getters.userInfo) {
    try {
      await store.dispatch('user/getUserInfo')
    } catch (e) {
      await store.dispatch('user/logOut')
    }
  }
  // TODO
  console.log(
    '动态路由页面刷新，由于路由重制，addroute刚添加',
    to,
    store.getters.userInfo
  )
  // if (getToken() && store.getters.userInfo) {
  //   if (to.redirectedFrom && to.name === 'login') {
  //     Message.error(i18n.t('message.quanxian') as string)
  //   }
  // }
  // debugger
  // 判断路由path和route是否相等
  function matchPath(path: any, route: any) {
    const pathParts = path.split('/')
    const routeParts = route.split('/')

    if (pathParts.length !== routeParts.length) {
      return false
    }

    for (let i = 0; i < routeParts.length; i++) {
      if (routeParts[i] === '*') {
        return true
      } else if (routeParts[i].startsWith(':')) {
        // Handle path parameters
        continue
      } else if (pathParts[i] !== routeParts[i]) {
        return false
      }
    }

    return true
  }
  const routerArr = router.getRoutes()
  const routerIndex = routerArr.findIndex((item: any) => {
    // console.log(pathToRegexp(item.path).exec(to.path))
    // console.log(to.path, item.path, matchPath(to.path, item.path))
    return pathToRegexp(item.path).exec(to.path)
    // return matchPath(to.path, item.path)
  })
  if (routerIndex === -1) {
    console.log("this is  ================ begin routers ========")
    console.log(routerArr)
    Message.error(i18n.t('message.quanxian') as string)
    // console.log('reloadNum', reloadNum)
    reloadNum ? next(false) : next('/')
    return
  }
  if (to.path === '/' && to.name !== '/') {
    Message.error(i18n.t('message.quanxian') as string)
    // console.log('reloadNum', reloadNum)
    reloadNum ? next(false) : next('/')
    return
  }
  if (
    getToken() &&
    !authenticated &&
    to.fullPath.indexOf('/login') < 0 &&
    to.fullPath.indexOf('/subscribe') < 0
  ) {
    next('/subscribe')
  }

  if (getToken() && to.path === '/login') {
    next({ name: 'home' })
    return
  }

  if (getToken()) {
    if (reloadNum) {
      next()
    } else {
      reloadNum++
      next({ ...to, replace: true })
    }
    return
  }
})

router.afterEach((to: any) => {
  Nprogress.done()
  if (to.meta.name) {
    document.title = to.meta.name
  }
})

router.onError((error: any) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})

// Duplicate route error
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(
  location: import('vue-router').RawLocation
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return originalPush.call(this, location).catch((err: any) => err)
}

export default router
