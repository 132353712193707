// import store from '@/store'
// console.log(store.getters.routers[0].children)
export const sidebarMenu: any = [
  {
    label: '首页',
    aliasLabel: '首页',
    name: 'home',
    icon: 'icondashboard',
    hidden: true,
    children: []
  },
  // {
  //   label: '部署',
  //   aliasLabel: '部署指南',
  //   name: 'deployment',
  //   icon: 'iconzhinan',
  //   children: [
  //     {
  //       label: '环境部署',
  //       name: 'environment',
  //     },
  //     {
  //       label: 'Agent 安装',
  //       name: 'deploy',
  //     },
  //     {
  //       label: '巡检排查',
  //       name: 'investigate',
  //     }
  //   ]
  // },
  {
    label: '项目',
    aliasLabel: '项目管理',
    name: 'project',
    icon: 'iconxiangmu1',
    children: [
      {
        label: '项目管理',
        aliasLabel: '项目管理',
        pName: 'project',
        name: 'groups'
      },
      {
        label: '应用列表',
        aliasLabel: '应用列表',
        name: 'pList',
        pName: 'project',
        children: [
          {
            label: '应用详情',
            aliasLabel: '应用详情',
            name: 'appDetail/:pid',
            pName: 'project',
          },
          {
            label: '应用修改',
            name: 'appEdit/:pid?',
            pName: 'project',
          },
          {
            label: '生成报告',
            name: "appExport/:pid?",
            pName: 'project',
          }
        ]
      },
      {
        label: '应用漏洞',
        name: 'vulnList',
        pName: 'project',
        children: [
          {
            label: '应用漏洞详情',
            name: 'leakDetail/:page/:id',
            pName: 'vulnList',
          },
        ]
      },
      {
        label: '组件漏洞',
        name: 'packageList',
        pName: 'project',
        children: [
          {
            label: '组件漏洞详情',
            name: 'packageDetail/:page/:id',
            pName: 'packageList',
          },
        ]
      },
      // {
      //   label: '黑盒漏洞',
      //   name: 'scanList',
      //   pName: 'project',
      //   children: [
      //     {
      //       name: 'scanDetail/:page/:id',
      //       pName: 'scanList',
      //     },
      //   ]
      // },
      {
        label: 'Agent 管理',
        pName: 'project',
        name: 'agent',
        children: [
          {
            name: "sysInfo",
            aliasLabel: '熔断配置',
            pName: 'agent',
          }
        ]
      },
    ]
  },
  {
    label: '修改密码',
    aliasLabel: '修改密码',
    name: 'settingCode',
    hiddenMenu: true,
    children:[]
  },
  {
    label: '集成',
    aliasLabel: '集成管理',
    name: 'stting',
    icon: 'iconjicheng',
    pName:'stting',
    hidden: true,
    children: [
      { name: 'jira', pName: 'stting', aliasLabel:'Jira配置'},
      { name: 'settingZentao', pName: 'stting', aliasLabel:'禅道配置'},
      { name: 'settingGitlab', pName: 'stting', aliasLabel:'GItlab配置'},
      { name: 'settingJenkins', pName: 'stting',aliasLabel:'Jenkins配置' },
      { name: 'settingFeishu', pName: 'stting',aliasLabel:'飞书配置' },
      { name: 'settingDingtalk', pName: 'stting', aliasLabel:'钉钉配置'},
      { name: 'settingWorkwx', pName: 'stting',aliasLabel:'企业微信配置' },
      { name: 'settinghook', pName: 'stting', aliasLabel:'webhook配置'},
      { name: 'settingIDEA', pName: 'stting',aliasLabel:'IDEA配置' },
      { name: 'settingK8S', pName: 'stting',aliasLabel:'K8S配置' },
      { name: 'settingYunXiao', pName: 'stting', aliasLabel:'云效配置'},
      { name: 'settingBlueKing', pName: 'stting', aliasLabel:'蓝鲸配置'},
    ]
  },
  {
    label: '策略',
    aliasLabel: '策略管理',
    name: 'policyManage',
    icon: 'iconcelve',
    children: [
      {
        label: '检测策略',
        name: 'passivePolicy',
        pName: 'policyManage',
        children: [
          { name: 'policy',pName: 'passivePolicy', aliasLabel: '策略配置',},
        ]
      },
      {
        label: '策略模版',
        name: 'settingTemplate',
        pName: 'policyManage',
        children: [
          { name: 'template' ,pName: 'settingTemplate',aliasLabel: '策略模版',},
        ]
      },
      {
       label: '规则设置',
       pName: 'policyManage',
       name: 'hookRule',
      },
      {
        label: '规则设置',
        pName: 'policyManage',
        name: 'settingSensitive',
        hidden: true,
        children: [{ name: 'sensitive',pName: 'settingSensitive', }]
      },
      // {
      //   label: '项目模版',
      //   pName: 'strategyBox',
      //   name: 'projectTemplate',
      // },
      {
        label: '系统设置',
        pName: 'policyManage',
        name: 'systemSettings',
      },
    ]
  },
  {
    label: '数据',
    aliasLabel: '数据查询',
    name: 'data',
    icon: 'iconchaxun1',
    hidden: true,
    children: [{ name: 'poolDetail', pName: 'data', }]
  },
  {
    label: '报告',
    aliasLabel: '报告中心',
    name: 'report',
    icon: 'iconbaogao',
    children: [
      {
        label: '应用报告',
        pName: 'report',
        name: 'report',
      },
      {
        label: '分享记录',
        pName: 'report',
        name: 'Share',
      },
    ]
  },

  {
    label: '系统',
    aliasLabel: '系统管理',
    icon: 'authority',
    name: 'permission',
    children: [
      {
        label: '角色管理',
        pName: 'permission',
        name: 'role'
      },
      {
        label: '角色管理',
        pName: 'permission',
        name: 'role',
        hidden: true,
        children: [
          {
            label: '权限设置',
            pName: 'permission', 
            name: 'addRole',
          },
        ]
      },
      // {
      //   label: '项目组',
      //   pName: 'authority',
      //   name: 'team'
      // },
      {
        label: '账号管理',
        pName: 'permission',
        name: 'id'
      },

      {
        label: '授权更新',
        pName: 'permission',
        name: 'subscribe',
        children: []
      },
      {
        label: 'Logo设置',
        pName: 'permission',
        name: 'settingLogo',
        children: []
      },
      {
        label: '操作日志',
        pName: 'permission',
        name: 'audit',
        children: []
      },
      {
        label: '关于MicroView',
        pName: 'permission',
        name: 'about',
        children: []
      },
    ]
  },


]
export const topMenu: any = [
  // {
  //   label: '漏洞检测',
  //   name: 'dashboard',
  //   // sidebarMenu: store.getters.routers[0].children
  //   sidebarMenu: sidebarMenu
  // },
  // {
  //   label: '关联图谱',
  //   name: 'links',
  //   sidebarMenu: []
  // },
  // {
  //   label: 'microview知识库',
  //   name: 'knowledge',
  //   sidebarMenu: []
  // },
]
export const menu: any = [
  {
    label: '权限管理',
    name: 'permission',
    children: [
      {
        label: '角色管理',
        name: 'role'
      },
      {
        label: '项目组',
        name: 'project'
      },
      {
        label: '账号管理',
        name: 'id'
      },
    ]
  },
  {
    label: '授权更新',
    name: 'license',
    children: []
  },
  {
    label: '品牌配置',
    name: 'settingLogo',
    children: []
  },
  {
    label: '操作日志',
    name: 'audit',
    children: []
  },
  {
    label: '关于MicroView',
    name: 'about',
    children: []
  },
]